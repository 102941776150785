import {
  Button,
  Heading,
  Layout,
  Link,
  LinkGroup,
  Paragraph,
  PromoBanner as AuroraPromoBanner,
  TIconGraphic
} from '@hurtigruten/design-system-components';

import { B2cExpeditionPromoBanner } from '@content/gql/graphql';
import { useLocale, useMediaQuery, useTranslate } from '@hooks';
import { ContentfulImage } from '@atoms';
import { ExternalLinkSite } from '@content/models/footerInfo';
import { footer } from '@microcopies';
import { TPromoBanner } from '@content/models/editorialContent';
import { breakpoints } from '@src/utils';

interface IPromoBanner {
  promo: B2cExpeditionPromoBanner | TPromoBanner;
  socialMediaLinks?: ExternalLinkSite[] | null;
}

const PromoBanner = ({ promo, socialMediaLinks }: IPromoBanner) => {
  const locale = useLocale();
  const translate = useTranslate(footer, (x) => x.footer);

  const {
    cta,
    descriptionPosition,
    image,
    promoBannerDescription,
    showSocialMediaLinks,
    title
  } = promo;

  const canShowSocialMediaLinks =
    showSocialMediaLinks?.toLowerCase() === 'yes' && socialMediaLinks;

  const buttonUrl = cta?.slug || '/';

  const getSocialMediaIcon = (label: string): TIconGraphic | undefined => {
    if (!label) {
      return undefined;
    }
    const [icon] = label.split(' ');
    return icon.toLowerCase() as unknown as TIconGraphic;
  };

  const position: 'left' | 'right' =
    descriptionPosition?.toLowerCase() === 'left' ? 'left' : 'right';

  const isTablet = useMediaQuery(breakpoints.tablet);

  return (
    <AuroraPromoBanner position={position}>
      <AuroraPromoBanner.Media>
        <ContentfulImage
          src={image?.url}
          alt={image?.alt}
          {...(isTablet && { layout: 'fill' })}
          objectFit="cover"
          {...(!isTablet && { width: 400 })}
          {...(!isTablet && { height: 342 })}
          sizes="(max-width: 768px),
          (max-width: 1440px)"
        />
      </AuroraPromoBanner.Media>
      <AuroraPromoBanner.Content>
        <Layout stack="full" horizontalGapOnly gap="none">
          <Layout.Column>
            <Heading
              level="1"
              noOrphans={false}
              appearance="headline-2"
              text={title || ''}
            />
            <Paragraph appearance="headline-5" color="dark">
              {promoBannerDescription}
            </Paragraph>
            <Button
              appearance="primary"
              fillMobileWidth={false}
              href={`/${locale}${buttonUrl}`}
              text={cta?.label}
            />
          </Layout.Column>
        </Layout>
        {canShowSocialMediaLinks && (
          <Layout horizontalGapOnly>
            <Layout.Column>
              <Heading
                level="2"
                appearance="headline-5"
                text={translate((x) => x.followUs)}
              />
              <LinkGroup ariaLabel="Social media links">
                {socialMediaLinks
                  ?.filter((x) => x.urlText)
                  .map((x) => (
                    <Link
                      href={x.url}
                      appearance="standalone"
                      text={x.label ?? ''}
                      key={x.url}
                      icon={getSocialMediaIcon(x.label ?? '')}
                      iconOnly
                    />
                  ))}
              </LinkGroup>
            </Layout.Column>
          </Layout>
        )}
      </AuroraPromoBanner.Content>
    </AuroraPromoBanner>
  );
};

export default PromoBanner;
