import clsx from 'clsx';
import { ReactChild } from 'react';

const Shimmer = ({
  children,
  isShimmering,
  className,
  isDark = false,
  isFullHeight = false,
  isBlend = false,
  containerClassName
}: {
  children: ReactChild;
  isShimmering: boolean;
  className?: string;
  isDark?: boolean;
  isFullHeight?: boolean;
  isBlend?: boolean;
  containerClassName?: string;
}) => (
  <div
    className={clsx({
      'animate-shimmer': isShimmering && !isDark,
      'animate-shimmer-dark': isShimmering && isDark,
      'h-full': isFullHeight,
      'opacity-50': isBlend,
      [containerClassName || '']: Boolean(containerClassName)
    })}
  >
    <div
      className={clsx('flex duration-500 transition-opacity', {
        'opacity-0': isShimmering,
        'opacity-100': !isShimmering,
        [className || '']: Boolean(className)
      })}
    >
      {children}
    </div>
  </div>
);

export default Shimmer;
