import React from 'react';

import { ISharedIconProps } from './SharedIcon-types';

export const ABTAIcon = ({
  className = '',
  fill = 'white',
  width = 69,
  height = 40
}: ISharedIconProps) => (
  <svg
    className={className}
    fill="none"
    viewBox="0 0 69 40"
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M39.4321 18.112H34.627V2.96857H39.3599C42.4183 2.96857 43.8194 5.0601 43.8194 6.89838C43.8194 8.34667 43.2204 9.31251 42.0042 10.0463V10.0915C43.7519 10.4815 44.8064 11.7006 44.8064 13.5389C44.8064 15.6998 43.2435 18.111 39.4321 18.111V18.112ZM38.9709 5.31529H37.407V9.26629H38.8755C40.1206 9.26629 41.0383 8.46415 41.0383 7.19786C41.0383 6.11935 40.3036 5.31625 38.9709 5.31625V5.31529ZM39.4986 11.587H37.408V15.771H39.1779C41.0393 15.771 41.957 14.941 41.957 13.5649C41.957 12.3911 41.0595 11.588 39.4986 11.588V11.587Z"
      fill={fill}
    />
    <path
      d="M55.9603 5.2912H51.9274V18.1129H49.1233V5.2912H44.7351V2.96951H56.8645L55.9612 5.2912H55.9603Z"
      fill={fill}
    />
    <path
      d="M30.3967 18.112L28.9003 14.315H23.4952L22.0922 18.112H19.3083L25.1679 2.96469H27.2517L33.3193 18.112H30.3967ZM26.8087 8.56524C26.5304 7.80739 26.3475 7.18532 26.2339 6.68266H26.2088C26.1183 7.21036 25.9132 7.80739 25.659 8.5219L24.3455 12.0492H28.0317L26.8078 8.56524H26.8087Z"
      fill={fill}
    />
    <path
      d="M65.0872 18.112L63.5917 14.315H58.1857L56.7836 18.112H53.9997L59.8583 2.96469H61.9431L68.0097 18.112H65.0862H65.0872ZM61.5001 8.56524C61.2209 7.80739 61.0389 7.18532 60.9253 6.68266H60.9002C60.8107 7.21036 60.6027 7.80739 60.3494 8.5219L59.0369 12.0492H62.7231L61.5011 8.56524H61.5001Z"
      fill={fill}
    />
    <path
      d="M9.94843 0.911682C9.65954 0.911682 9.37355 0.926126 9.0914 0.952126V2.42545V18.5963V19.1732C9.37355 19.2001 9.65858 19.2155 9.94843 19.2155C15.003 19.2155 19.0994 15.1172 19.0994 10.0626C19.0994 5.00809 15.003 0.912645 9.94843 0.912645V0.911682ZM13.2947 10.8975C13.2571 11.4811 13.182 12.0521 13.0674 12.6058H10.9239V10.8975H13.2947ZM10.9239 9.18828V7.47999H13.0578C13.1734 8.03273 13.2523 8.60473 13.2918 9.18828H10.9249H10.9239ZM15.0935 10.8975H17.2399C17.1725 11.4907 17.0377 12.0637 16.8374 12.6058H14.8633C14.977 12.0531 15.055 11.482 15.0925 10.8975H15.0935ZM15.0916 9.18828C15.0502 8.60376 14.9693 8.03273 14.8528 7.47999H16.823C17.0271 8.02117 17.1648 8.59317 17.2351 9.18828H15.0925H15.0916ZM15.9024 5.77171H14.3568C14.0497 4.94646 13.6577 4.1761 13.1926 3.47699C14.2731 4.00951 15.2023 4.80106 15.9024 5.77171ZM12.5657 5.77171H10.9239V2.79041C11.6028 3.66381 12.1594 4.66817 12.5657 5.77171ZM10.9239 17.3387V14.316H12.5831C12.1757 15.434 11.6153 16.4557 10.9278 17.3387H10.9249H10.9239ZM13.21 16.6396C13.6789 15.9318 14.0708 15.1509 14.379 14.316H15.9313C15.2312 15.2982 14.2981 16.0994 13.2109 16.6396H13.21Z"
      fill={fill}
    />
    <path
      d="M8.22661 8.33701V7.64272L3.84517 3.26031C3.60154 3.47891 3.36947 3.71001 3.15088 3.95172L7.53617 8.33701H8.22661Z"
      fill={fill}
    />
    <path
      d="M8.22661 11.7391V12.4334L3.84517 16.8158C3.60154 16.5972 3.36947 16.3661 3.15088 16.1235L7.53617 11.7391H8.22661Z"
      fill={fill}
    />
    <path
      d="M8.22662 9.21042H0.836909C0.809946 9.49064 0.795502 9.77471 0.795502 10.0617C0.795502 10.3486 0.809946 10.6327 0.836909 10.9139H8.22662V9.21042Z"
      fill={fill}
    />
    <path
      d="M2.66941 4.53336C1.83356 5.63209 1.24231 6.9263 0.976532 8.33703H6.47404L2.66941 4.53336Z"
      fill={fill}
    />
    <path
      d="M8.22667 6.58058V1.08789C6.81787 1.35367 5.52558 1.94492 4.42493 2.77884L8.22667 6.58058Z"
      fill={fill}
    />
    <path
      d="M4.42584 17.2964C5.5265 18.1312 6.81878 18.7215 8.22662 18.9883V13.4956L4.42584 17.2964Z"
      fill={fill}
    />
    <path
      d="M6.47404 11.7391H0.976532C1.24231 13.1489 1.83452 14.4431 2.66941 15.5428L6.47308 11.7391H6.47404Z"
      fill={fill}
    />
    <path
      d="M5.9473 22.9094H4.72146V26.7112H3.89043V22.9094H2.58948V22.2219H6.14567L5.94826 22.9094H5.9473Z"
      fill={fill}
    />
    <path
      d="M7.88763 24.245C7.75089 24.1632 7.60837 24.143 7.5063 24.143C7.31563 24.143 7.10474 24.2315 6.88615 24.429V26.7112H6.0686V23.4891H6.75712L6.85245 23.83C7.17985 23.5373 7.43118 23.414 7.74511 23.414C7.89533 23.414 8.07251 23.4621 8.19481 23.5507L7.88859 24.245H7.88763Z"
      fill={fill}
    />
    <path
      d="M10.3808 26.7112L10.244 26.4252C10.0331 26.6158 9.74039 26.7795 9.31091 26.7795C8.71773 26.7795 8.25455 26.378 8.25455 25.7443C8.25455 25.077 8.77936 24.7159 9.61039 24.688L10.1824 24.6678V24.5859C10.1824 24.1978 9.90313 24.0409 9.56224 24.0409C9.22135 24.0409 8.93536 24.1564 8.73121 24.2797L8.47218 23.7684C8.90165 23.4824 9.3032 23.414 9.73268 23.414C10.53 23.414 11.0067 23.7414 11.0067 24.5859V25.8599C11.0067 26.3231 11.0202 26.5619 11.0683 26.7112H10.3808ZM10.1766 25.233L9.71342 25.2465C9.27046 25.26 9.07306 25.4102 9.07306 25.6827C9.07306 25.9552 9.25698 26.1324 9.59787 26.1324C9.88387 26.1324 10.0745 26.0236 10.1766 25.9283V25.233Z"
      fill={fill}
    />
    <path
      d="M13.2282 26.7314H12.635L11.3071 23.4882H12.1449L12.949 25.6818H12.9624L13.78 23.4882H14.5706L13.2282 26.7314Z"
      fill={fill}
    />
    <path
      d="M15.5566 25.3149C15.5701 25.7916 15.9649 26.1257 16.4628 26.1257C16.769 26.1257 17.0213 26.0506 17.3006 25.9013L17.498 26.4261C17.1369 26.6784 16.7074 26.7805 16.326 26.7805C15.2023 26.7805 14.7256 25.963 14.7256 25.0982C14.7256 24.1786 15.2706 23.415 16.1903 23.415C17.0627 23.415 17.6347 24.0756 17.6347 25.077V25.3159H15.5566V25.3149ZM16.17 24.0274C15.7752 24.0274 15.5364 24.3818 15.5364 24.7362H16.7902C16.7767 24.3885 16.586 24.0274 16.17 24.0274Z"
      fill={fill}
    />
    <path
      d="M18.9896 26.7795C18.4513 26.7795 18.2472 26.4733 18.2472 26.0708V21.8877H19.0647V25.7097C19.0647 25.9485 19.1129 26.0438 19.2823 26.0438C19.3372 26.0438 19.4046 26.0371 19.5212 26.0101L19.6232 26.6168C19.3844 26.7458 19.1735 26.7805 18.9896 26.7805V26.7795Z"
      fill={fill}
    />
    <path
      d="M25.2295 26.7314H24.6094L23.8872 24.5378H23.867L23.1448 26.7314H22.5246L21.3527 23.4882H22.1837L22.8722 25.6683H22.8925L23.6012 23.4882H24.2213L24.9436 25.675H24.957L25.6378 23.4882H26.4217L25.2295 26.7314Z"
      fill={fill}
    />
    <path
      d="M27.3278 22.9913C27.0418 22.9913 26.8242 22.7736 26.8242 22.4944C26.8242 22.2151 27.0418 22.0042 27.3278 22.0042C27.6138 22.0042 27.8314 22.2219 27.8314 22.4944C27.8314 22.7669 27.6138 22.9913 27.3278 22.9913ZM26.9195 26.7112V23.4891H27.737V26.7112H26.9195Z"
      fill={fill}
    />
    <path
      d="M29.6851 26.7795C29.1131 26.7795 28.8878 26.4598 28.8878 26.0236V24.1025H28.3158V23.4891H28.8878V22.7467L29.7053 22.5011V23.4891H30.7068L30.468 24.1025H29.7053V25.7241C29.7053 25.9764 29.8007 26.092 30.0116 26.092C30.2224 26.092 30.4276 25.9967 30.5498 25.9148L30.7675 26.4329C30.4333 26.6717 29.9981 26.7805 29.6842 26.7805L29.6851 26.7795Z"
      fill={fill}
    />
    <path
      d="M33.3087 26.7111V24.8315C33.3087 24.4704 33.2538 24.1295 32.8186 24.1295C32.5393 24.1295 32.2533 24.2864 32.0347 24.4704V26.7121H31.2172V21.8887H32.0347V23.8367C32.3342 23.5912 32.6886 23.414 33.1046 23.414C33.6024 23.414 34.133 23.6528 34.133 24.5994V26.7111H33.3087Z"
      fill={fill}
    />
    <path
      d="M37.9338 26.7795C36.8852 26.7795 36.3469 26.0236 36.3469 25.1175C36.3469 24.1362 37.0556 23.414 38.0157 23.414C38.4519 23.414 38.7514 23.5228 38.9353 23.6384L38.9218 24.3057C38.6974 24.1353 38.4451 24.0399 38.111 24.0399C37.5592 24.0399 37.1644 24.4761 37.1644 25.0751C37.1644 25.6269 37.4639 26.117 38.1043 26.117C38.4105 26.117 38.6425 26.0217 38.8669 25.8926L39.0711 26.4309C38.812 26.6081 38.3768 26.7786 37.9338 26.7786V26.7795Z"
      fill={fill}
    />
    <path
      d="M42.1583 26.3163C41.8723 26.6091 41.4765 26.7795 40.9931 26.7795C40.5434 26.7795 40.1621 26.636 39.8828 26.378C39.5621 26.0785 39.3849 25.6355 39.3849 25.1175C39.3849 24.5994 39.5554 24.1776 39.8549 23.8704C40.1409 23.5844 40.5289 23.414 40.9989 23.414C41.4967 23.414 41.8983 23.5844 42.1843 23.8772C42.4635 24.1767 42.6137 24.5994 42.6137 25.0895C42.6137 25.5797 42.4568 26.0159 42.1573 26.3154L42.1583 26.3163ZM40.9863 24.0611C40.4683 24.0611 40.2169 24.4973 40.2169 25.0963C40.2169 25.6952 40.5097 26.1449 41.0075 26.1449C41.5054 26.1449 41.7769 25.702 41.7769 25.0963C41.7769 24.4906 41.4909 24.0611 40.9863 24.0611Z"
      fill={fill}
    />
    <path
      d="M45.3456 26.7112V24.817C45.3456 24.4829 45.284 24.115 44.869 24.115C44.583 24.115 44.3037 24.2653 44.0514 24.4559V26.7112H43.2339V23.4891H43.9281L44.0167 23.8367C44.3644 23.5777 44.712 23.414 45.1472 23.414C45.6374 23.414 46.1622 23.7067 46.1622 24.5724V26.7112H45.3447H45.3456Z"
      fill={fill}
    />
    <path
      d="M48.7728 22.6302C48.6438 22.5753 48.5003 22.5416 48.3645 22.5416C48.1055 22.5416 47.9832 22.6985 47.9832 22.9778V23.4891H48.8344L48.6235 24.116H47.9832V26.7112H47.1656V24.116H46.6957V23.4891H47.1656V23.0394C47.1656 22.0861 47.8541 21.8877 48.3038 21.8877C48.4743 21.8877 48.7266 21.9282 48.9375 22.0312L48.7738 22.6302H48.7728Z"
      fill={fill}
    />
    <path
      d="M49.8003 22.9913C49.5143 22.9913 49.2966 22.7736 49.2966 22.4944C49.2966 22.2151 49.5143 22.0042 49.8003 22.0042C50.0863 22.0042 50.3039 22.2219 50.3039 22.4944C50.3039 22.7669 50.0863 22.9913 49.8003 22.9913ZM49.391 26.7112V23.4891H50.2085V26.7112H49.391Z"
      fill={fill}
    />
    <path
      d="M52.6747 26.7795C51.7137 26.7795 50.9241 26.2143 50.9241 25.1242C50.9241 24.1227 51.6665 23.414 52.7084 23.414C52.8721 23.414 53.0012 23.4207 53.1167 23.441V21.8877H53.9343V26.5205C53.628 26.6775 53.2255 26.7795 52.6738 26.7795H52.6747ZM53.1177 24.1093C53.0089 24.0823 52.9135 24.0611 52.7364 24.0611C52.1576 24.0611 51.7416 24.4492 51.7416 25.0828C51.7416 25.6683 52.123 26.1045 52.7498 26.1045C52.8789 26.1045 53.0223 26.0843 53.1177 26.0564V24.1083V24.1093Z"
      fill={fill}
    />
    <path
      d="M55.3662 25.3149C55.3796 25.7916 55.7744 26.1257 56.2723 26.1257C56.5785 26.1257 56.8308 26.0506 57.1101 25.9013L57.3075 26.4261C56.9464 26.6784 56.5169 26.7805 56.1356 26.7805C55.0118 26.7805 54.5351 25.963 54.5351 25.0982C54.5351 24.1786 55.0802 23.415 55.9998 23.415C56.8722 23.415 57.4442 24.0756 57.4442 25.077V25.3159H55.3662V25.3149ZM55.9786 24.0274C55.5838 24.0274 55.345 24.3818 55.345 24.7362H56.5987C56.5853 24.3885 56.3946 24.0274 55.9786 24.0274Z"
      fill={fill}
    />
    <path
      d="M60.1684 26.7112V24.817C60.1684 24.4829 60.1067 24.115 59.6917 24.115C59.4057 24.115 59.1265 24.2653 58.8742 24.4559V26.7112H58.0566V23.4891H58.7509L58.8395 23.8367C59.1871 23.5777 59.5347 23.414 59.97 23.414C60.4601 23.414 60.985 23.7067 60.985 24.5724V26.7112H60.1674H60.1684Z"
      fill={fill}
    />
    <path
      d="M63.1719 26.7795C62.1232 26.7795 61.5849 26.0236 61.5849 25.1175C61.5849 24.1362 62.2937 23.414 63.2537 23.414C63.6899 23.414 63.9894 23.5228 64.1733 23.6384L64.1599 24.3057C63.9355 24.1353 63.6832 24.0399 63.3491 24.0399C62.7973 24.0399 62.4025 24.4761 62.4025 25.0751C62.4025 25.6269 62.702 26.117 63.3423 26.117C63.6485 26.117 63.8806 26.0217 64.105 25.8926L64.3091 26.4309C64.0501 26.6081 63.6148 26.7786 63.1719 26.7786V26.7795Z"
      fill={fill}
    />
    <path
      d="M65.454 25.3149C65.4675 25.7916 65.8623 26.1257 66.3602 26.1257C66.6664 26.1257 66.9187 26.0506 67.198 25.9013L67.3954 26.4261C67.0343 26.6784 66.6048 26.7805 66.2234 26.7805C65.0997 26.7805 64.623 25.963 64.623 25.0982C64.623 24.1786 65.168 23.415 66.0877 23.415C66.9601 23.415 67.5321 24.0756 67.5321 25.077V25.3159H65.454V25.3149ZM66.0674 24.0274C65.6726 24.0274 65.4338 24.3818 65.4338 24.7362H66.6876C66.6741 24.3885 66.4834 24.0274 66.0674 24.0274Z"
      fill={fill}
    />
    <path
      d="M1.55334 38.9686L4.16103 32.1788H5.1288L7.90788 38.9686H6.88426L6.09271 36.9127H3.25392L2.5086 38.9686H1.55334ZM3.51199 36.1809H5.81346L5.10472 34.3002C4.88902 33.7292 4.72821 33.2602 4.62324 32.8924C4.53658 33.3276 4.41525 33.76 4.25732 34.1895L3.51199 36.1809Z"
      fill={fill}
    />
    <path
      d="M8.49341 38.9686V32.1788H11.0404C11.5595 32.1788 11.9755 32.2472 12.2884 32.3849C12.6014 32.5226 12.8469 32.7335 13.0251 33.0195C13.2023 33.3055 13.2918 33.604 13.2918 33.916C13.2918 34.2059 13.2128 34.4793 13.0559 34.7355C12.898 34.9916 12.6611 35.1987 12.3423 35.3556C12.7526 35.476 13.0684 35.6811 13.2889 35.9719C13.5094 36.2618 13.6202 36.6046 13.6202 37.0003C13.6202 37.3181 13.5528 37.6137 13.4189 37.8872C13.2851 38.1607 13.1185 38.3716 12.9211 38.5189C12.7237 38.6672 12.4752 38.7789 12.1777 38.855C11.8801 38.9311 11.5142 38.9686 11.0828 38.9686H8.49437H8.49341ZM9.39184 35.0321H10.8604C11.259 35.0321 11.544 35.0061 11.7174 34.9531C11.9456 34.8847 12.118 34.7721 12.2335 34.6151C12.3491 34.4572 12.4069 34.2598 12.4069 34.0219C12.4069 33.7966 12.3529 33.5982 12.2451 33.4268C12.1372 33.2554 11.9822 33.1379 11.7819 33.0744C11.5816 33.0108 11.2369 32.979 10.7486 32.979H9.39184V35.0311V35.0321ZM9.39184 38.1674H11.0818C11.3717 38.1674 11.5758 38.1569 11.6933 38.1347C11.9003 38.0981 12.0727 38.0355 12.2123 37.9498C12.351 37.8632 12.4656 37.738 12.5552 37.5723C12.6447 37.4077 12.689 37.216 12.689 37.0003C12.689 36.7471 12.6245 36.5275 12.4945 36.3407C12.3645 36.1539 12.1854 36.0229 11.9552 35.9469C11.7251 35.8708 11.3938 35.8332 10.9615 35.8332H9.39184V38.1674Z"
      fill={fill}
    />
    <path
      d="M16.4898 38.9686V32.98H14.2528V32.1788H19.6338V32.98H17.3872V38.9686H16.4888H16.4898Z"
      fill={fill}
    />
    <path
      d="M19.0108 38.9686L21.6185 32.1788H22.5862L25.3653 38.9686H24.3417L23.5501 36.9127H20.7114L19.966 38.9686H19.0117H19.0108ZM20.9704 36.1809H23.2718L22.5631 34.3002C22.3474 33.7292 22.1866 33.2602 22.0816 32.8924C21.995 33.3276 21.8736 33.76 21.7157 34.1895L20.9704 36.1809Z"
      fill={fill}
    />
    <path
      d="M27.9961 38.9686V32.1788H28.9176L32.4834 37.5098V32.1788H33.3453V38.9686H32.4237L28.8579 33.6339V38.9686H27.9961Z"
      fill={fill}
    />
    <path
      d="M34.3429 36.5092C34.3429 35.5983 34.5962 34.9242 35.1027 34.4851C35.5254 34.1211 36.0415 33.9391 36.6492 33.9391C37.3252 33.9391 37.8779 34.1606 38.3074 34.6035C38.7369 35.0465 38.9516 35.6589 38.9516 36.4399C38.9516 37.0725 38.8563 37.5704 38.6666 37.9334C38.4769 38.2965 38.2005 38.5776 37.8375 38.7789C37.4744 38.9792 37.0786 39.0803 36.6492 39.0803C35.9607 39.0803 35.4041 38.8598 34.9794 38.4178C34.5547 37.9768 34.3429 37.3402 34.3429 36.5102V36.5092ZM35.1999 36.5092C35.1999 37.139 35.3376 37.6108 35.6121 37.9238C35.8865 38.2368 36.2322 38.3937 36.6492 38.3937C37.0661 38.3937 37.407 38.2368 37.6815 37.9209C37.9559 37.606 38.0936 37.1255 38.0936 36.4803C38.0936 35.8717 37.9549 35.4114 37.6795 35.0975C37.4032 34.7846 37.0594 34.6276 36.6492 34.6276C36.239 34.6276 35.8865 34.7836 35.6121 35.0956C35.3376 35.4076 35.1999 35.8785 35.1999 36.5082V36.5092Z"
      fill={fill}
    />
    <path d="M40.0696 38.9686V38.0191H41.0191V38.9686H40.0696Z" fill={fill} />
    <path
      d="M44.3933 38.9686V36.0923L41.7769 32.1788H42.8699L44.2084 34.2261C44.4549 34.6093 44.685 34.9916 44.8988 35.3749C45.103 35.0195 45.3495 34.6199 45.6403 34.175L46.9557 32.1788H48.0024L45.2936 36.0923V38.9686H44.3952H44.3933Z"
      fill={fill}
    />
    <path
      d="M52.6988 33.8418L51.8697 33.9063C51.7955 33.5789 51.6905 33.3411 51.5548 33.1928C51.3294 32.9549 51.0511 32.8365 50.7209 32.8365C50.4551 32.8365 50.222 32.9107 50.0217 33.0589C49.7589 33.2506 49.5528 33.5298 49.4016 33.8977C49.2504 34.2655 49.1715 34.7884 49.1657 35.4673C49.366 35.162 49.6115 34.9348 49.9023 34.7865C50.1922 34.6382 50.4965 34.564 50.8143 34.564C51.3699 34.564 51.8427 34.7682 52.2337 35.1774C52.6246 35.5867 52.8191 36.1154 52.8191 36.7634C52.8191 37.1891 52.7277 37.5858 52.5437 37.9517C52.3598 38.3176 52.1075 38.5979 51.7868 38.7924C51.4662 38.9869 51.1012 39.0842 50.6939 39.0842C49.9996 39.0842 49.4324 38.829 48.9943 38.3176C48.5561 37.8063 48.3366 36.9647 48.3366 35.7908C48.3366 34.4783 48.5792 33.525 49.0636 32.9289C49.4863 32.4099 50.0564 32.1509 50.7729 32.1509C51.3073 32.1509 51.7445 32.3011 52.0854 32.6006C52.4262 32.9001 52.6314 33.3141 52.6988 33.8418ZM49.2947 36.7692C49.2947 37.0562 49.3554 37.3316 49.4777 37.5935C49.6 37.8564 49.7704 38.0557 49.989 38.1934C50.2086 38.3311 50.4387 38.3995 50.6794 38.3995C51.0319 38.3995 51.3343 38.2579 51.5875 37.9739C51.8408 37.6898 51.9669 37.3037 51.9669 36.8164C51.9669 36.3291 51.8417 35.9777 51.5914 35.7071C51.341 35.4365 51.0261 35.3017 50.6467 35.3017C50.2673 35.3017 49.9505 35.4365 49.6876 35.7071C49.4247 35.9777 49.2938 36.332 49.2938 36.7702L49.2947 36.7692Z"
      fill={fill}
    />
    <path
      d="M54.8365 35.2872C54.4908 35.1611 54.2347 34.9801 54.0681 34.7451C53.9015 34.5101 53.8177 34.2299 53.8177 33.9025C53.8177 33.4085 53.9949 32.9935 54.3502 32.6564C54.7056 32.3204 55.1774 32.1519 55.7677 32.1519C56.358 32.1519 56.8375 32.3242 57.1986 32.668C57.5598 33.0127 57.7408 33.4316 57.7408 33.9256C57.7408 34.2405 57.658 34.5149 57.4933 34.748C57.3277 34.981 57.0773 35.1611 56.7412 35.2872C57.1582 35.423 57.475 35.6426 57.6926 35.9449C57.9103 36.2473 58.0191 36.6084 58.0191 37.0283C58.0191 37.6089 57.814 38.0962 57.4028 38.492C56.9926 38.8868 56.4514 39.0851 55.7821 39.0851C55.1129 39.0851 54.5717 38.8868 54.1615 38.49C53.7513 38.0933 53.5452 37.5983 53.5452 37.0061C53.5452 36.5651 53.6569 36.1953 53.8813 35.8968C54.1047 35.5992 54.4234 35.3961 54.8375 35.2882L54.8365 35.2872ZM54.4013 37.01C54.4013 37.2478 54.4571 37.478 54.5707 37.7004C54.6834 37.9228 54.851 38.0952 55.0734 38.2165C55.2958 38.3388 55.5347 38.3995 55.7908 38.3995C56.1895 38.3995 56.5178 38.2714 56.7769 38.0153C57.0359 37.7591 57.1659 37.4337 57.1659 37.0379C57.1659 36.6421 57.0321 36.3041 56.7653 36.0422C56.4986 35.7803 56.1644 35.6483 55.7629 35.6483C55.3613 35.6483 55.0455 35.7783 54.7884 36.0374C54.5303 36.2964 54.4013 36.6209 54.4013 37.01ZM54.6699 33.8746C54.6699 34.1952 54.773 34.4581 54.98 34.6623C55.187 34.8664 55.4557 34.9675 55.786 34.9675C56.1163 34.9675 56.3705 34.8664 56.5756 34.6642C56.7807 34.462 56.8838 34.2145 56.8838 33.9208C56.8838 33.6271 56.7778 33.3584 56.5669 33.1495C56.3551 32.9415 56.0922 32.8365 55.7773 32.8365C55.4624 32.8365 55.1957 32.9386 54.9858 33.1418C54.7759 33.3459 54.6709 33.5895 54.6709 33.8736L54.6699 33.8746Z"
      fill={fill}
    />
    <path
      d="M58.7384 37.1766L59.5724 37.0658C59.6677 37.5386 59.8314 37.8785 60.0606 38.0865C60.2907 38.2945 60.5709 38.3995 60.9012 38.3995C61.2932 38.3995 61.6244 38.2637 61.895 37.9922C62.1656 37.7206 62.3004 37.3836 62.3004 36.983C62.3004 36.5824 62.1752 36.2849 61.9249 36.0355C61.6745 35.787 61.3567 35.6628 60.9706 35.6628C60.8126 35.6628 60.6172 35.6936 60.3822 35.7552L60.4746 35.0234C60.5305 35.0292 60.5748 35.033 60.6085 35.033C60.9638 35.033 61.2835 34.9406 61.5676 34.7547C61.8517 34.5698 61.9932 34.2838 61.9932 33.8977C61.9932 33.5924 61.8902 33.3392 61.6831 33.1379C61.4761 32.9376 61.2094 32.8365 60.882 32.8365C60.5546 32.8365 60.2878 32.9386 60.0712 33.1418C59.8555 33.3459 59.7158 33.6512 59.6542 34.0585L58.8203 33.9102C58.9224 33.3517 59.1535 32.9184 59.5146 32.6112C59.8757 32.304 60.3254 32.1499 60.8627 32.1499C61.2335 32.1499 61.5743 32.2299 61.8863 32.3887C62.1983 32.5476 62.4362 32.7643 62.6018 33.0397C62.7665 33.3141 62.8493 33.6059 62.8493 33.915C62.8493 34.2241 62.7703 34.4755 62.6134 34.7162C62.4564 34.9569 62.2224 35.1486 61.9143 35.2901C62.3158 35.3826 62.6278 35.5752 62.8493 35.8669C63.0717 36.1587 63.1825 36.5237 63.1825 36.9628C63.1825 37.556 62.9668 38.0577 62.5344 38.4698C62.102 38.8819 61.556 39.088 60.8945 39.088C60.2984 39.088 59.8035 38.9108 59.4106 38.5555C59.0167 38.2002 58.7924 37.7408 58.7365 37.1756L58.7384 37.1766Z"
      fill={fill}
    />
    <path
      d="M63.9142 37.19L64.7896 37.1159C64.8541 37.5415 65.0053 37.8622 65.2412 38.0769C65.4771 38.2916 65.7622 38.3985 66.0953 38.3985C66.4969 38.3985 66.8359 38.2473 67.1141 37.945C67.3924 37.6426 67.5311 37.2411 67.5311 36.7413C67.5311 36.2415 67.3973 35.891 67.1305 35.6156C66.8638 35.3412 66.5142 35.2035 66.0819 35.2035C65.8132 35.2035 65.5705 35.2641 65.3548 35.3864C65.1391 35.5087 64.9687 35.6666 64.8454 35.8612L64.0625 35.7591L64.7202 32.2722H68.0964V33.0686H65.3876L65.0216 34.8934C65.429 34.6093 65.8565 34.4678 66.3043 34.4678C66.8975 34.4678 67.3973 34.6729 67.8046 35.084C68.2119 35.4943 68.4161 36.0229 68.4161 36.6681C68.4161 37.3133 68.237 37.814 67.8787 38.2608C67.4435 38.8107 66.8493 39.0851 66.0953 39.0851C65.4781 39.0851 64.9735 38.9128 64.5835 38.5661C64.1925 38.2204 63.9701 37.762 63.9142 37.191V37.19Z"
      fill={fill}
    />
  </svg>
);
