import { useEffect } from 'react';

export const useOnLoad = (
  onLoad?: () => void,
  onResize?: (e?: UIEvent) => void,
  deps?: any[]
) => {
  useEffect(() => {
    const onLoadInternal = () => {
      if (!['complete', 'ready'].includes(document.readyState)) return;

      document.removeEventListener('readystatechange', onLoadInternal);
      onLoad?.();
    };

    if (onResize) window.addEventListener('resize', onResize);

    if (document.readyState === 'complete' && onLoad) {
      setTimeout(onLoad, 0);
    }
    document.addEventListener('readystatechange', onLoadInternal);

    return () => {
      document.removeEventListener('readystatechange', onLoadInternal);
      if (onResize) window.removeEventListener('resize', onResize);
    };
  }, [...(deps ?? [])]);
};
