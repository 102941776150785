import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import NextLink from 'next/link';
import {
  BrandFooter,
  Button as AuroraButton,
  Heading,
  Layout,
  Paragraph,
  Link,
  LinkGroup,
  List,
  TIconGraphic
} from '@hurtigruten/design-system-components';

import {
  useLocale,
  useMediaQuery,
  useTranslate,
  useIsBookingFlow
} from '@hooks';
import { footer } from '@microcopies';
import { FooterCountrySelector, TalkToUsModal } from '@components/molecules';
import { PromoBanner } from '@components/organisms';
import { externalLinkMixin, isFeatureEnabled, breakpoints } from '@utils';
import { useSiteData } from '@context/DataContext';
import { ContactInfo } from '@content/models/contactInfo';
import { get } from '@src/utils/http';
import { ambassadorSysId } from '@content/requests/getFooter';
import {
  mapLocaleToMakeAPaymentUri,
  mapLocaleToNewsLetterUri,
  mapLocaleToPaymentUri
} from '@src/utils/mappers/uriMappers';
import { ATOLIcon, ABTAIcon } from '@components/shared/icons';

const Footer = ({
  hideNewsletterPromotion = false
}: {
  hideNewsletterPromotion?: boolean;
}) => {
  const [isTalkToUsOpen, setIsTalkToUsOpen] = useState(false);
  const [contactInfo, setContactInfo] = useState<ContactInfo | null>(null);

  const router = useRouter();
  const isTablet = useMediaQuery(breakpoints.tablet);
  const translate = useTranslate(footer, (x) => x.footer);
  const locale = useLocale();
  const isBookingFlow = useIsBookingFlow({});
  const isCountrySelectionActive = isFeatureEnabled('countrySelection');
  const isLoyaltyActive = isFeatureEnabled('isLoyaltyActive');
  const isGuestPayment = router.pathname.startsWith(
    `/${mapLocaleToMakeAPaymentUri(locale)}/${mapLocaleToPaymentUri(locale)}`
  );
  const isUKMarket = locale === 'en-gb';

  const { data: siteData } = useSiteData();
  const { footer: footerData } = siteData;

  const {
    aboutLinks,
    destinationLinks,
    goToLinks,
    supportLinks,
    coastalLinks,
    legalLinks,
    socialMediaLinks,
    newsletterPromotion
  } = footerData ?? {
    aboutLinks: [],
    goToLinks: [],
    supportLinks: [],
    destinationLinks: [],
    coastalLinks: [],
    legalLinks: [],
    socialMediaLinks: [],
    newsletterPromotion: null
  };

  if (!isLoyaltyActive) {
    const ambassadorIndex = aboutLinks?.findIndex(
      (link) => link?.id === ambassadorSysId
    );
    if (ambassadorIndex !== -1) {
      aboutLinks.splice(ambassadorIndex, 1);
    }
  }

  useEffect(() => {
    if (!contactInfo) {
      void get<ContactInfo>()(
        `/api/content/contactInfo/?locale=${locale}`
      ).then(setContactInfo);
    }
  }, [contactInfo]);

  if (isBookingFlow || isGuestPayment) {
    return null;
  }

  const getSocialMediaIcon = (label: string): TIconGraphic | undefined => {
    if (!label) {
      return undefined;
    }
    const [icon] = label.split(' ');
    return icon.toLowerCase() as unknown as TIconGraphic;
  };

  const returnListItem = (url: string, label: string | undefined) =>
    url.startsWith('/') ? (
      <List.Item key={url}>
        <NextLink href={url} passHref legacyBehavior>
          <Link text={label ?? ''} appearance="standalone" />
        </NextLink>
      </List.Item>
    ) : (
      <List.Item key={url}>
        <Link
          text={label ?? ''}
          href={url}
          {...externalLinkMixin(url)}
          appearance="standalone"
        />
      </List.Item>
    );

  return (
    <>
      {newsletterPromotion && !hideNewsletterPromotion && (
        <PromoBanner
          promo={newsletterPromotion}
          socialMediaLinks={socialMediaLinks}
        />
      )}
      <BrandFooter>
        {!newsletterPromotion && (
          <Layout {...(isTablet && { border: 'bottom' })}>
            <Layout container>
              <Layout grid="67_33" gap="double">
                <Layout.Column>
                  <Heading
                    level="2"
                    appearance="headline-5"
                    text={translate((x) => x.campaign.title)}
                  />
                  <Paragraph color="light">
                    {translate((x) => x.campaign.text)}
                  </Paragraph>
                  <div className="flex">
                    <AuroraButton
                      appearance="tertiary"
                      href={`/${locale}/${mapLocaleToNewsLetterUri(locale)}`}
                      text={translate((x) => x.campaign.button)}
                    />
                  </div>
                </Layout.Column>
                <Layout.Column>
                  <Heading
                    level="2"
                    appearance="headline-5"
                    text={translate((x) => x.followUs)}
                  />
                  <LinkGroup ariaLabel="Social media links">
                    {socialMediaLinks
                      ?.filter((x) => x.urlText)
                      .map((x) => (
                        <Link
                          href={x.url}
                          appearance="standalone"
                          text={x.label ?? ''}
                          key={x.url}
                          icon={getSocialMediaIcon(x.label ?? '')}
                          iconOnly
                        />
                      ))}
                  </LinkGroup>
                </Layout.Column>
              </Layout>
            </Layout>
          </Layout>
        )}
        <Layout {...(isTablet && { border: 'bottom' })}>
          <Layout container>
            <Layout grid="25_25_25_25" gap="double">
              <Layout.Column>
                <nav
                  id="footer-contact-information-links"
                  aria-label="Contact Information"
                  className="border-b border-[#858585] pb-6 tablet:pb-0 tablet:border-0"
                >
                  <Heading
                    level="2"
                    appearance="headline-5"
                    text={translate((x) => x.contact.title)}
                  />
                  {isUKMarket && (
                    <Paragraph color="light">
                      HX Group Ltd, 210 Pentonville Rd, London, N1 9JY (Reg. No.
                      14918176)
                    </Paragraph>
                  )}
                  {contactInfo && (
                    <Layout stack="full">
                      <List kind="labelled" wrap>
                        {contactInfo?.phoneNumbers?.map(
                          ({ label, countryCode, phoneNumber }) => (
                            <List.Item key={label} label={label}>
                              <Link
                                href={`tel:${countryCode ?? ''} ${phoneNumber}`}
                                text={`${countryCode ?? ''} ${phoneNumber}`}
                              />
                            </List.Item>
                          )
                        )}
                      </List>
                    </Layout>
                  )}
                  {Boolean(contactInfo?.phoneNumbers?.length) && (
                    <Paragraph color="light">
                      {contactInfo?.phoneNumbers[0].availability}
                    </Paragraph>
                  )}
                  <List>
                    <List.Item>
                      <Link
                        appearance="standalone"
                        onClick={() => setIsTalkToUsOpen(true)}
                        text={translate((x) => x.support.contactUs)}
                      />
                    </List.Item>
                  </List>
                </nav>
              </Layout.Column>
              <Layout.Column>
                <nav id="footer-support-links" aria-label="Support Links">
                  <Heading
                    level="2"
                    appearance="headline-5"
                    text={translate((x) => x.support.title)}
                  />
                  {isUKMarket && (
                    <List>
                      <List.Item>
                        <Link
                          text={'Essential Travel Advice'}
                          href="https://www.gov.uk/foreign-travel-advice"
                          target="_blank"
                          appearance="standalone"
                        />
                      </List.Item>
                    </List>
                  )}
                  <List>
                    {supportLinks?.map(({ url, label }) =>
                      returnListItem(url, label)
                    )}
                  </List>
                  {isUKMarket && (
                    <div className="flex items-center gap-4 mb-10 tablet:mb-0">
                      <a
                        href="https://www.caa.co.uk/atol-protection/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <ATOLIcon />
                      </a>
                      <a
                        href="http://www.abta.com/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <ABTAIcon />
                      </a>
                    </div>
                  )}
                </nav>
              </Layout.Column>
              <Layout.Column>
                <nav
                  id="about-links"
                  aria-label="About Links"
                  className="mb-10 tablet:mb-0"
                >
                  <Heading
                    level="2"
                    appearance="headline-5"
                    text={translate((x) => x.about.title)}
                  />

                  <List>
                    {aboutLinks?.map(({ url, label }) =>
                      returnListItem(url, label)
                    )}
                  </List>
                </nav>
              </Layout.Column>
              <Layout.Column>
                <Layout stack="full">
                  {isCountrySelectionActive && (
                    <FooterCountrySelector id="country-selector-footer" />
                  )}
                </Layout>
                <Heading
                  level="2"
                  appearance="headline-5"
                  text={translate((x) => x.goTo.title)}
                />
                <List>
                  {goToLinks?.map((link) => (
                    <List.Item key={link.url}>
                      <Link
                        appearance="standalone"
                        href={link.url}
                        text={link.label ?? ''}
                        icon="arrow-right"
                      />
                    </List.Item>
                  ))}
                </List>
              </Layout.Column>
            </Layout>
          </Layout>
        </Layout>

        <Layout border="bottom">
          <Layout container>
            <Layout>
              <Layout.Column>
                <nav
                  id="footer-destination-links"
                  aria-label="Destination Links"
                >
                  <Heading
                    level="2"
                    appearance="headline-5"
                    text={translate((x) => x.hurtigrutenExpeditions)}
                  />
                  {destinationLinks && (
                    <List columns="4">
                      {destinationLinks.map((link) =>
                        returnListItem(link.url, link?.label)
                      )}
                    </List>
                  )}
                </nav>
              </Layout.Column>
            </Layout>
            <Layout>
              <Layout.Column>
                {coastalLinks && (
                  <nav
                    id="coastal-links"
                    aria-label="Norwegian Coastal Express Links"
                  >
                    <Heading
                      level="2"
                      appearance="headline-5"
                      text={translate((x) => x.norwegianCoastalExpress)}
                    />
                    <List columns="4">
                      {coastalLinks.map((coastalLink) =>
                        returnListItem(coastalLink.url, coastalLink?.label)
                      )}
                    </List>
                  </nav>
                )}
              </Layout.Column>
            </Layout>
          </Layout>
        </Layout>

        <Layout container>
          <Layout grid="33_67">
            <Layout.Column>
              <Paragraph appearance="body-1" color="light">
                {translate((x) => x.copyright)}
              </Paragraph>
            </Layout.Column>
            <Layout.Column>
              <LinkGroup>
                {legalLinks?.map(({ url, label }) =>
                  url.startsWith('/') ? (
                    <NextLink href={url} passHref legacyBehavior key={url}>
                      <Link color="light" text={label ?? ''} />
                    </NextLink>
                  ) : (
                    <NextLink href={url} passHref legacyBehavior key={url}>
                      <Link
                        color="light"
                        text={label ?? ''}
                        key={url}
                        {...externalLinkMixin(url)}
                      />
                    </NextLink>
                  )
                )}
                {isFeatureEnabled('isCrimtanEnabled') && (
                  <button className="ot-sdk-show-settings">
                    {translate((x) => x.managePreferences)}
                  </button>
                )}
              </LinkGroup>
            </Layout.Column>
          </Layout>
        </Layout>
        <TalkToUsModal
          id="talktous-footer"
          open={isTalkToUsOpen}
          onClose={() => setIsTalkToUsOpen(false)}
        />
      </BrandFooter>
    </>
  );
};

export default Footer;
