import { ChangeEvent } from 'react';

import { footer } from '@microcopies';
import { useLocale, useTranslate } from '@hooks';
import { useLocaleHelpers, TCountryCode } from '@hooks/useLocaleHelpers';
import { DEFAULT_LOCALE_URL } from '@constants/locales';
import {
  addLocaleToLocalStorage,
  hxLocale
} from '@src/utils/locale/localeStorage';

import Dropdown from './Dropdown';

const FooterCountrySelector = ({
  onSelect,
  id,
  wrapperClassName
}: {
  onSelect?: () => void;
  id?: string;
  wrapperClassName?: string;
}) => {
  const translate = useTranslate(footer, (x) => x.footer);
  const locale = useLocale();

  const {
    selectorCountriesToLocaleMap: websites,
    getSelectorCountryFromLocale,
    getCountryFromCountryCode
  } = useLocaleHelpers();

  const optionsWebsites = websites.map((website) => ({
    description: website.name,
    value: website.countryCode
  }));

  let selectedWebsite = getSelectorCountryFromLocale(locale);

  const addToStorage = (newLocale: TLocale) => {
    if (typeof window !== 'undefined') {
      addLocaleToLocalStorage(window, newLocale);
    }
  };

  const onChange = (countryCode: TCountryCode) => {
    const website = getCountryFromCountryCode(countryCode);
    if (website) {
      selectedWebsite = website;
      addToStorage(website.locale as TLocale);
      const newLocale = website.locale as string;
      window.location.assign(`/${newLocale}/`);
      document.cookie = `${hxLocale}=${newLocale};path=/;maxAge=34560000`;
      onSelect?.();
    } else {
      window.location.assign(DEFAULT_LOCALE_URL);
    }
  };

  return (
    <div className="flex flex-col w-full gap-4">
      <label className="text-white h5-text" htmlFor={id}>
        {translate((x) => x.changeCountry)}
      </label>
      <Dropdown
        isDark
        wrapperClassName={wrapperClassName}
        id={id}
        options={optionsWebsites}
        value={selectedWebsite?.countryCode}
        onChange={(e: ChangeEvent<HTMLSelectElement>) =>
          onChange(e.target.value as TCountryCode)
        }
      />
    </div>
  );
};

export default FooterCountrySelector;
