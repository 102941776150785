import React, { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';

import { XrayContext } from '@context/XrayProvider';

const ContentfulXRAY = ({
  title,
  contentType,
  entryID,
  className,
  children
}: {
  title?: string;
  contentType?: string;
  entryID?: string;
  children: JSX.Element | JSX.Element[];
  className?: string;
}) => {
  const { xRayState } = useContext(XrayContext);
  const [show, setShow] = useState<boolean>();
  const SPACEID = process.env.CONTENTFUL_B2C_EXPEDITION_SPACE_ID;

  const URL =
    SPACEID && entryID
      ? `https://app.contentful.com/spaces/${SPACEID}/entries/${entryID}`
      : undefined;

  useEffect(() => {
    setShow(xRayState);
  }, [xRayState]);

  return show ? (
    <div
      className={clsx('relative border-dashed', className, {
        'border-slate-700 border-2 ': show
      })}
    >
      <div
        className={clsx('px-5 min-h-4 z-100 bg-warm-gray-2', { hidden: !show })}
      >
        <a
          className={clsx('text-xs ', {
            'text-red-700 line-through': !URL
          })}
          href={URL}
          target="_blank"
          rel="noreferrer"
        >
          <span>{title}</span>
          {contentType && <span> / {contentType}</span>}
        </a>
      </div>

      {children}
    </div>
  ) : (
    <>{children}</>
  );
};

export default ContentfulXRAY;
