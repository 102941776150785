import React from 'react';

import { ISharedIconProps } from './SharedIcon-types';

export const ATOLIcon = ({
  className = '',
  fill = 'white',
  width = 68,
  height = 68
}: ISharedIconProps) => (
  <svg
    className={className}
    fill="none"
    viewBox="0 0 68 68"
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M39.905 0.519848C21.4442 -2.73494 3.77587 9.63517 0.520123 28.0969C-2.73466 46.5577 9.63642 64.2251 28.0972 67.4799C46.559 70.7346 64.2263 58.3636 67.4811 39.9037C67.8316 37.9152 68.0011 35.9364 68.0011 33.9835C68.0011 17.8049 56.3773 3.42508 39.905 0.519848ZM65.3212 39.5224C62.2763 56.7911 45.7491 68.3639 28.4795 65.319C11.2108 62.2732 -0.362905 45.746 2.68196 28.4773C5.72682 11.2077 22.254 -0.364144 39.5236 2.68072C56.7933 5.72654 68.3661 22.2537 65.3202 39.5224H65.3212Z"
      fill={fill}
    />
    <path
      d="M22.8549 41.274L24.5256 41.5735L23.9373 40.1223L22.8549 41.274Z"
      fill={fill}
    />
    <path
      d="M31.5167 34.5959C24.9955 27.7869 24.9455 19.6961 29.0351 12.2958L23.6069 11.3319L17.5442 45.7364L45.1271 50.5925L46.9538 40.313C46.947 40.313 38.0349 41.4011 31.5157 34.5969L31.5167 34.5959ZM25.1111 42.9707L24.8087 42.2148L22.3763 41.7825L21.811 42.3843L20.3753 42.1301L23.3103 39.2287L24.8626 39.5041L26.6017 43.2375L25.112 42.9717L25.1111 42.9707ZM32.6943 41.5013L30.728 41.1517L30.2427 43.8827L28.8599 43.6371L29.3442 40.9052L27.3682 40.5547L27.4732 39.9673L32.7983 40.9129L32.6934 41.5013H32.6943ZM39.3647 43.7873C39.2588 44.3815 38.8467 44.7801 38.1273 44.9785C37.5842 45.1489 36.8447 45.1509 35.9039 44.9843C34.965 44.8158 34.2697 44.5606 33.8191 44.213C33.2095 43.7777 32.9572 43.2616 33.0622 42.6655C33.171 42.0579 33.5851 41.6611 34.3054 41.4743C34.8475 41.3039 35.589 41.3029 36.5288 41.4705C37.4677 41.6371 38.1639 41.8942 38.6136 42.2399C39.2222 42.6636 39.4726 43.1778 39.3647 43.7864V43.7873ZM44.7476 46.4595L40.1168 45.6362L40.7071 42.3169L42.0812 42.5615L41.5969 45.2847L44.8526 45.8625L44.7486 46.4595H44.7476Z"
      fill={fill}
    />
    <path
      d="M41.3426 14.4374C41.3349 14.4413 30.1916 20.1776 35.5514 30.852C39.4331 38.5778 47.2455 38.6779 47.2455 38.6779L51.2408 16.1968L41.359 14.4403L41.3426 14.4374Z"
      fill={fill}
    />
    <path
      d="M37.6651 42.5942C37.381 42.3342 36.9679 42.157 36.4239 42.0598C35.8808 41.9625 35.4263 41.9856 35.0671 42.132C34.7069 42.2774 34.4931 42.5355 34.4267 42.9091C34.3603 43.2827 34.472 43.6015 34.7589 43.8595C35.0468 44.1205 35.4638 44.2996 36.0079 44.3959C36.5519 44.4932 37.0016 44.4672 37.357 44.3218C37.7142 44.1764 37.9242 43.9144 37.9906 43.5427C38.057 43.1701 37.9473 42.8523 37.6642 42.5923L37.6651 42.5942Z"
      fill={fill}
    />
    <path
      d="M11.335 37.9345L4.42966 39.819C4.51825 40.0655 4.61743 40.3082 4.6858 40.5605C4.75417 40.8118 4.79269 41.0718 4.8418 41.3289L7.87222 40.5017C8.36718 42.314 9.10673 43.5177 10.8978 43.0285C12.0351 42.7185 12.3933 41.8094 12.011 40.4103L11.335 37.9335V37.9345ZM10.2902 41.5186C9.67583 41.6862 9.03643 41.6014 8.67628 40.9957C8.56843 40.7983 8.49525 40.5691 8.42399 40.3505L11.2021 39.5927L11.3726 40.2167C11.5151 40.7405 11.0943 41.2991 10.2892 41.5186H10.2902Z"
      fill={fill}
    />
    <path
      d="M14.1565 45.4195L13.0308 43.5032L6.85829 47.1268C7.00851 47.3416 7.1674 47.5505 7.29933 47.7749C7.43125 48.0002 7.53622 48.241 7.65081 48.4759L10.5397 46.7802L10.5763 46.8428C10.3509 47.2414 10.0842 47.7364 9.92049 48.0137L8.65902 50.1938C8.83524 50.4529 9.02109 50.7071 9.17902 50.9777C9.34272 51.2569 9.47464 51.5429 9.61523 51.8232L9.96768 51.1443C10.4877 50.113 10.9874 49.0691 11.5614 48.0667C12.2325 48.7976 13.1233 49.0373 14.0053 48.5202C14.6178 48.161 14.8633 47.6526 14.8325 46.9824C14.8085 46.4884 14.405 45.8413 14.1565 45.4186V45.4195ZM13.4651 46.9025C13.2976 47.0488 13.1676 47.1374 13.0414 47.2116C12.1141 47.7556 11.39 47.5525 10.9249 46.5548L13.3361 45.1393C13.6587 45.6882 14.1267 46.3208 13.4642 46.9034L13.4651 46.9025Z"
      fill={fill}
    />
    <path
      d="M19.16 51.1924C17.2052 49.4813 14.9605 49.748 13.5922 51.3109C12.0322 53.0933 12.3086 55.1377 14.0428 56.6562C15.9668 58.3404 18.2847 58.0679 19.6174 56.5301C20.6747 55.3062 21.3969 53.1511 19.16 51.1915V51.1924ZM18.4455 55.2397C17.3044 56.5426 15.7039 57.2773 14.4954 56.2191C13.3177 55.1887 13.8031 53.699 14.6967 52.6783C16.2365 50.9199 17.6096 50.6657 18.7007 51.6209C19.8783 52.6523 19.3323 54.2267 18.4455 55.2397Z"
      fill={fill}
    />
    <path
      d="M22.2887 54.3635C22.227 54.5069 22.1404 54.6283 22.0633 54.7544C22.5901 54.9027 23.2227 55.0963 23.9026 55.3909L21.3546 61.2804C21.6021 61.3651 21.8544 61.4392 22.0942 61.5432C22.3339 61.6472 22.5602 61.7792 22.7913 61.9015L25.3393 56.012C25.9238 56.2643 26.4592 56.5754 26.9956 56.8874C27.0341 56.7448 27.064 56.5985 27.1256 56.455C27.1834 56.3211 27.27 56.1998 27.348 56.0737L22.4158 53.9398C22.3763 54.0823 22.3474 54.2286 22.2896 54.3625L22.2887 54.3635Z"
      fill={fill}
    />
    <path
      d="M27.22 63.2794L31.1392 63.9439C31.1431 63.7965 31.1392 63.6367 31.1623 63.4922C31.1874 63.3488 31.2442 63.1995 31.2885 63.0589C30.6471 63.0348 29.9384 62.9674 28.8878 62.7999L29.3308 60.1874L30.4006 60.3694C30.8638 60.4474 31.2779 60.56 31.6505 60.6766C31.6544 60.5292 31.6505 60.3694 31.6746 60.2249C31.6987 60.0815 31.7555 59.9322 31.8008 59.7916C31.4117 59.7781 30.9832 59.7483 30.52 59.6693L29.4502 59.4883L29.8257 57.2764C30.8782 57.4333 31.5639 57.6346 32.1782 57.8233C32.1821 57.676 32.1782 57.5161 32.2023 57.3727C32.2264 57.2292 32.2832 57.0799 32.3285 56.9393L28.4092 56.2749L27.2209 63.2804L27.22 63.2794Z"
      fill={fill}
    />
    <path
      d="M37.8721 63.3334C36.2726 63.4884 35.2788 62.3271 35.1074 60.5504C34.9591 59.0242 35.4955 57.3053 37.2202 57.1377C37.8952 57.0723 38.5982 57.4025 39.0363 57.9264L39.1817 57.9129C39.2299 57.5412 39.2578 57.182 39.3165 56.82C38.6819 56.5455 37.8336 56.4502 37.148 56.5166C34.884 56.7362 33.1343 58.1537 33.3972 60.8644C33.6043 62.9935 35.4194 64.202 37.6419 63.9853C38.3382 63.9179 39.0633 63.7224 39.6555 63.3392L39.797 62.4244L39.6564 62.3752C39.2318 62.8664 38.5163 63.2718 37.873 63.3343L37.8721 63.3334Z"
      fill={fill}
    />
    <path
      d="M45.1607 55.1733C45.0875 55.0452 45.0057 54.92 44.9479 54.7746C44.8949 54.6389 44.8709 54.4915 44.8362 54.3481L39.8336 56.3106C39.9059 56.4386 39.9887 56.5638 40.0416 56.6996C40.0985 56.846 40.1235 56.9923 40.1572 57.1368C40.6358 56.87 41.2203 56.5628 41.9107 56.2923L44.2546 62.2655C44.4895 62.1509 44.7216 62.0267 44.9643 61.9304C45.2069 61.835 45.4621 61.7695 45.7125 61.6935L43.3677 55.7203C43.9599 55.4872 44.5598 55.3303 45.1597 55.1743L45.1607 55.1733Z"
      fill={fill}
    />
    <path
      d="M52.5581 56.5263C52.0863 56.9606 51.5364 57.4131 50.6727 58.0352L49.1454 55.8695L50.0323 55.2436C50.4156 54.973 50.7901 54.7612 51.1349 54.5811C51.0338 54.4732 50.9182 54.3635 50.8335 54.2441C50.7487 54.1247 50.6842 53.9783 50.6178 53.8473C50.3318 54.1121 50.0063 54.3924 49.623 54.6639L48.7362 55.2898L47.4429 53.4564C48.3009 52.8256 48.9288 52.4857 49.4969 52.1862C49.3958 52.0784 49.2802 51.9686 49.1965 51.8492C49.1117 51.7298 49.0472 51.5844 48.9808 51.4525L45.7318 53.7433L49.8272 59.5509L53.0762 57.26C52.9751 57.1522 52.8595 57.0424 52.7748 56.923C52.691 56.8036 52.6255 56.6572 52.5591 56.5263H52.5581Z"
      fill={fill}
    />
    <path
      d="M56.8106 47.4764C55.4682 46.4152 53.6791 46.1706 52.2288 47.8712L50.015 50.6705L55.5896 55.078L57.4018 52.7861C58.7288 51.1077 58.6604 48.9391 56.8106 47.4764ZM56.6353 52.7784L56.0855 53.4737L51.4604 49.8174L52.095 49.0152C53.0069 47.8606 54.1894 47.6112 55.7282 48.8274C57.6435 50.3422 57.5289 51.6479 56.6353 52.7775V52.7784Z"
      fill={fill}
    />
    <path
      d="M27.9855 11.0392L26.6778 10.809L27.5463 5.87966C26.9898 6.24269 26.3677 6.4738 25.6821 6.57298L25.891 5.38566C26.2521 5.33366 26.6604 5.18729 27.1159 4.94655C27.5714 4.70582 27.9104 4.38997 28.1318 3.99997L29.193 4.18678L27.9855 11.0392Z"
      fill={fill}
    />
    <path
      d="M34.5721 10.9477L34.3583 12.1629L29.7728 11.355C29.9037 10.9043 30.129 10.4951 30.4497 10.1282C30.7704 9.76035 31.3568 9.30198 32.209 8.75117C32.8956 8.30628 33.3232 7.99718 33.4907 7.82481C33.718 7.58696 33.8557 7.33563 33.9019 7.07178C33.9529 6.78 33.9144 6.54215 33.7854 6.35726C33.6564 6.17334 33.4532 6.05682 33.1778 6.00771C32.9043 5.95956 32.6732 6.00386 32.4825 6.13964C32.2918 6.27541 32.1513 6.53252 32.0588 6.91L30.7781 6.54986C30.9822 5.84401 31.3173 5.37024 31.7824 5.12853C32.2475 4.8878 32.7868 4.82135 33.4012 4.9292C34.0743 5.04765 34.5721 5.32305 34.8928 5.75349C35.2134 6.1849 35.3261 6.67023 35.2308 7.20948C35.1769 7.51666 35.07 7.79977 34.9111 8.05784C34.7522 8.31591 34.5259 8.57591 34.2332 8.83784C34.0396 9.01213 33.7026 9.25191 33.223 9.55716C32.7435 9.86338 32.4373 10.0685 32.3053 10.1735C32.1734 10.2784 32.0627 10.3834 31.9741 10.4893L34.5712 10.9467L34.5721 10.9477Z"
      fill={fill}
    />
    <path
      d="M40.5819 7.94713L39.2915 7.86335C39.3069 7.59758 39.2597 7.39054 39.1509 7.24321C39.0421 7.09588 38.8842 7.0044 38.6762 6.9678C38.3998 6.91869 38.1446 7.00247 37.9097 7.21625C37.6747 7.43099 37.4638 7.92594 37.276 8.70305C37.6699 8.37564 38.109 8.25431 38.5924 8.34001C39.1384 8.43631 39.5698 8.72712 39.8856 9.21149C40.2015 9.69585 40.3016 10.2669 40.1861 10.9255C40.0628 11.6237 39.7595 12.1475 39.2751 12.4981C38.7908 12.8476 38.2274 12.966 37.5851 12.8534C36.8966 12.732 36.3766 12.3642 36.0281 11.7508C35.6785 11.1374 35.6111 10.2207 35.8268 9.0006C36.0473 7.74972 36.4373 6.88884 36.9968 6.41796C37.5563 5.94707 38.2053 5.77663 38.9439 5.90663C39.4619 5.99811 39.8654 6.21863 40.1543 6.56914C40.4432 6.91966 40.5848 7.37899 40.5809 7.94713H40.5819ZM37.1143 10.2775C37.0392 10.7031 37.0796 11.0478 37.2337 11.3136C37.3887 11.5794 37.5919 11.7344 37.8432 11.7787C38.0849 11.8211 38.3035 11.7624 38.498 11.6015C38.6925 11.4407 38.8283 11.1441 38.9044 10.7127C38.9824 10.2688 38.9525 9.92889 38.8158 9.692C38.6781 9.45512 38.4797 9.31356 38.2188 9.26734C37.9674 9.22304 37.7373 9.28371 37.5293 9.4503C37.3203 9.61689 37.1826 9.8923 37.1152 10.2775H37.1143Z"
      fill={fill}
    />
    <path
      d="M43.1096 13.7056L43.3513 12.3324L40.5588 11.8403L40.761 10.6954L44.4857 6.88306L45.5844 7.07661L44.8218 11.4061L45.6692 11.5553L45.467 12.7051L44.6196 12.5558L44.3779 13.929L43.1116 13.7056H43.1096ZM43.5545 11.1826L43.9657 8.85037L41.9859 10.9063L43.5545 11.1826Z"
      fill={fill}
    />
    <path
      d="M50.4782 13.7518L50.2644 14.9671L45.6788 14.1591C45.8098 13.7085 46.0351 13.2992 46.3558 12.9323C46.6764 12.5645 47.2629 12.1061 48.1151 11.5553C48.8017 11.1104 49.2292 10.8013 49.3968 10.6289C49.624 10.3911 49.7617 10.1398 49.808 9.87592C49.859 9.58414 49.8205 9.34629 49.6914 9.1614C49.5624 8.97748 49.3602 8.86096 49.0838 8.81185C48.8103 8.7637 48.5792 8.808 48.3886 8.94377C48.1979 9.07955 48.0573 9.33666 47.9649 9.71414L46.6841 9.35399C46.8883 8.64815 47.2234 8.17437 47.6885 7.93267C48.1536 7.69193 48.6929 7.62549 49.3072 7.73334C49.9803 7.85178 50.4782 8.12719 50.7988 8.55763C51.1195 8.98903 51.2322 9.47436 51.1378 10.0136C51.0839 10.3208 50.977 10.6039 50.8181 10.862C50.6592 11.1201 50.4329 11.3801 50.1402 11.642C49.9466 11.8163 49.6096 12.056 49.13 12.3613C48.6505 12.6675 48.3443 12.8726 48.2123 12.9776C48.0804 13.0826 47.9697 13.1875 47.8801 13.2934L50.4782 13.7508V13.7518Z"
      fill={fill}
    />
  </svg>
);
