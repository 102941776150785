import clsx from 'clsx';

import { Icon } from '@atoms';

const Dropdown = ({
  className,
  defaultValue,
  disabled,
  dropdownRef,
  icon,
  id,
  isDark = false,
  label,
  name,
  onChange,
  options,
  placeholder,
  value,
  wrapperClassName,
  hasError = false,
  ...rest
}: IDropdownProps) => {
  const errorClass = 'border-2 border-red-50';
  const disabledClass = 'opacity-40';
  const darkClass = 'bg-black text-white';
  const greyClass = 'text-gray';
  const selectClasses = clsx(
    'pl-5 pr-4 py-4 w-full border-none relative appearance-none overflow-hidden focus:outline-none focus-visible:outline-2 focus-visible:outline-penguin-yellow',
    {
      [className || '']: className,
      [darkClass]: isDark,
      [errorClass]: hasError,
      [disabledClass]: disabled
    }
  );
  const optionClasses = clsx({
    [darkClass]: isDark,
    [greyClass]: disabled
  });
  return (
    <div
      className={clsx('relative flex items-center', {
        'bg-black text-white': isDark,
        'bg-white': !isDark,
        [wrapperClassName || '']: wrapperClassName
      })}
    >
      {label && (
        <label htmlFor={id} className="pl-4 font-medium text-gray-400">
          {label}
        </label>
      )}
      <select
        name={name}
        aria-invalid={hasError}
        onChange={onChange}
        disabled={disabled}
        defaultValue={value ? undefined : defaultValue || placeholder}
        value={value}
        id={id}
        ref={dropdownRef}
        className={selectClasses}
        {...rest}
      >
        {placeholder && (
          <option value={placeholder} disabled>
            {placeholder}
          </option>
        )}
        {options.map(
          ({
            value: optionValue,
            disabled: optionDisabled,
            description,
            hidden
          }) => (
            <option
              className={optionClasses}
              key={optionValue}
              value={optionValue}
              disabled={optionDisabled}
              hidden={hidden}
            >
              {description || optionValue}
            </option>
          )
        )}
      </select>
      {icon && (
        <Icon
          icon={icon}
          className="absolute pointer-events-none color-white top-5 right-5"
        />
      )}
    </div>
  );
};

export default Dropdown;
